import React from 'react'
import '../Container/Contact.css'


function Contact() {
  return (
    <div class="my-5 py-2 justify-content-around">  
    <div class="container">
  <div class="box">
    <span></span>
    <div class="content">
      <h2 class='hed'><b>Contact</b></h2>
      <p><b> Sayaan&nbsp; &nbsp; &nbsp;: 7736864964  <br></br> Sidharth : 9496225620</b></p>
    </div>
  </div>
</div>
  
      
    </div>
    
  )
}

export default Contact
